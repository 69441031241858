<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="4"
                lg="6"
                md="6"
                sm="4"
            >
                <h3>Dashboard</h3>
            </v-col>
        </v-row>

        <v-row>
            <v-col 
                v-if="userLoggedGetters.profile.constructionPreDig != 0"
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <v-card @click="goToPipelinePreDig" class="preDig_alpha">
                    <v-card-title class="text-h6 preDig d-flex justify-space-between">
                        
                        <div>
                            <v-icon left class="pipelineIconTitle">mdi mdi-account-hard-hat-outline</v-icon>
                            <label class="pipelineLabelTitle">Pre-Dig</label>
                        </div>

                        <div style="text-align: right;">
                            <label class="labelHoldResumeValue">{{ totalPreDig }}</label>
                            <label class="labelHoldResume">Open</label>
                            <label class="pipelineLabelTitle"> | </label>
                            <!-- <br /> -->
                            <label class="labelHoldResumeValue">0</label>
                            <label class="labelHoldResume">On Hold</label>
                        </div>

                    </v-card-title> 
                    <v-card-text 
                        class="pipelineDetails"
                    >
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdIncoming }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPreSite }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdLocates }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PREDIG_LOCALES.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPreSiteResponsabilities }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE_RESP.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdRebar }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PREDIG_REBAR.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValueBlank">-</label>
                            </v-col>
                            <v-col 
                                cols="8"
                            >
                                <label class="pipelineLabelValueBlank">-</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col 
                v-if="userLoggedGetters.profile.constructionFrontEnd != 0"
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <v-card @click="goToPipelineFrontEnd" class="frontend_alpha">
                    <v-card-title class="text-h6 frontend d-flex justify-space-between">

                        <div>
                            <v-icon left class="pipelineIconTitle">mdi mdi-grid</v-icon>
                            <label class="pipelineLabelTitle">Front end</label>
                        </div>


                        <div style="text-align: right;">
                            <label class="labelHoldResumeValue"> {{ totalFrontEnd }}</label>
                            <label class="labelHoldResume">Open</label>
                            <label class="pipelineLabelTitle"> | </label>
                            <label class="labelHoldResumeValue">0</label>
                            <label class="labelHoldResume">On Hold</label>
                        </div>       

                    </v-card-title>
                    <v-card-text 
                        class="pipelineDetails"
                    >
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdDigDemo }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_FRONTEND_DIGDEMO.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdFormSteel }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_FRONTEND_FORMSTEEL.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdFrontEndInspections }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_FRONTEND_FRONTENDINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPrePlumb }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_FRONTEND_PREPLUMB.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdGunite }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_FRONTEND_GUNITE.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBackFill }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_FRONTEND_BACKFILL.description }}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <v-col 
                v-if="userLoggedGetters.profile.constructionGradingPlumbing != 0"
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <v-card @click="goToPipelineGradingPlumbing" class="grading_plumbing_alpha">
                    <v-card-title class="text-h6 grading_plumbing d-flex justify-space-between">

                        <div>
                            <v-icon left class="pipelineIconTitle">mdi mdi-pipe</v-icon>
                            <label class="pipelineLabelTitle">Grading / Plumbing</label>
                        </div>                        

                        <div style="text-align: right;">
                            <label class="labelHoldResumeValue"> {{ totalGradingPlumbing }} </label>
                            <label class="labelHoldResume">Open</label>
                            <label class="pipelineLabelTitle"> | </label>
                            <!-- <br /> -->
                            <label class="labelHoldResumeValue">0</label>
                            <label class="labelHoldResume">On Hold</label>
                        </div>

                    </v-card-title>
                    <v-card-text 
                        class="pipelineDetails"
                    >
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPlumbing }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPlumbingInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBINGINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPatioGradeBondingGrid }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PATIOGRADE.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBondingGridInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_DECKBONDINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValueBlank">-</label>
                            </v-col>
                            <v-col 
                                cols="8"
                            >
                                <label class="pipelineLabelValueBlank">-</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValueBlank">-</label>
                            </v-col>
                            <v-col 
                                cols="8"
                            >
                                <label class="pipelineLabelValueBlank">-</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <v-col 
                v-if="userLoggedGetters.profile.constructionTileDeck != 0"
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <v-card @click="goToPipelineTileDeck" class="tiledeck_alpha">
                    <v-card-title class="text-h6 tiledeck d-flex justify-space-between">

                        <div>
                            <v-icon left class="pipelineIconTitle">mdi mdi-wall</v-icon>
                            <label class="pipelineLabelTitle">Brick & Tile / Deck</label>
                        </div>
                        

                        <div style="text-align: right;">
                            <label class="labelHoldResumeValue">{{ totalBrickTileDeck }}</label>
                            <label class="labelHoldResume">Open</label>
                            <label class="pipelineLabelTitle"> | </label>
                            <!-- <br /> -->
                            <label class="labelHoldResumeValue">0</label>
                            <label class="labelHoldResume">On Hold</label>
                        </div>

                    </v-card-title>
                    <v-card-text 
                        class="pipelineDetails"
                    >
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdSendDelivery }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdDeckInstall }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSTALL.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdFinalGrade }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BRICKTILE_FINALGRADE.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdCleanUp }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BRICKTILE_CLEANUP.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdDeckInspections }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col 
                v-if="userLoggedGetters.profile.constructionEquipmentElectric != 0"
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <v-card @click="goToPipelineEquipmentElectric" class="equipment_electric_alpha">
                    <v-card-title class="text-h6 equipment_electric d-flex justify-space-between">

                        <div>
                            <v-icon left class="pipelineIconTitle">mdi mdi-home-lightning-bolt</v-icon>
                            <label class="pipelineLabelTitle">Equipment / Electric</label>
                        </div>                        

                        <div style="text-align: right;">
                            <label class="labelHoldResumeValue">{{ totalEquipmentElectric }}</label>
                            <label class="labelHoldResume">Open</label>
                            <label class="pipelineLabelTitle"> | </label>
                            <label class="labelHoldResumeValue">0</label>
                            <label class="labelHoldResume">On Hold</label>
                        </div>

                    </v-card-title> 
                    <v-card-text 
                        class="pipelineDetails"
                    >
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdEquipmentSet }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_EQUIPMENTSET.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdElectricHookUp }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_ELECTRICHOOKUP.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPrePlasterResponsabilities }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_PREPLASTER.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdInspections }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_INSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValueBlank">-</label>
                            </v-col>
                            <v-col 
                                cols="8"
                            >
                                <label class="pipelineLabelValueBlank">-</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col 
                v-if="userLoggedGetters.profile.constructionPrepPlaster != 0"
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <v-card @click="goToPipelinePrepPlaster" class="prep_plaster_alpha">
                    <v-card-title class="text-h6 prep_plaster d-flex justify-space-between">

                        <div>
                            <v-icon left class="pipelineIconTitle">mdi mdi-water-opacity</v-icon>
                            <label class="pipelineLabelTitle">Plaster</label>
                        </div>
                        
                        <div style="text-align: right;">
                            <label class="labelHoldResumeValue">{{ totalPlaste }}</label>
                            <label class="labelHoldResume">Open</label>
                            <label class="pipelineLabelTitle"> | </label>
                            <!-- <br /> -->
                            <label class="labelHoldResumeValue">0</label>
                            <label class="labelHoldResume">On Hold</label>
                        </div>

                    </v-card-title>
                    <v-card-text 
                        class="pipelineDetails"
                    >
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdFinalWalkthrough }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PLASTE_FINALWALKTHROUGHT.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdVKBowlDeliveryAndInstall }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PLASTE_VKBOWLDELIVERY.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPrep }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PLASTE_PREP.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPlaster }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PLASTE_PLASTER.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdOpenItems }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.description }}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <v-col 
                v-if="userLoggedGetters.profile.constructionBBQ != 0"
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <v-card @click="goToPipelineFence" class="fence_alpha">
                    <v-card-title class="text-h6 fence d-flex justify-space-between">

                        <div>
                            <v-icon left class="pipelineIconTitle" style="margin-top: -5px;">mdi mdi-fence</v-icon>
                            <label class="pipelineLabelTitle">Fence</label>
                        </div>

                        <div style="text-align: right;">
                            <label class="labelHoldResumeValue"> {{ totalFence }}</label>
                            <label class="labelHoldResume">Open</label>
                            <label class="pipelineLabelTitle"> | </label>
                            <!-- <br /> -->
                            <label class="labelHoldResumeValue">0</label>
                            <label class="labelHoldResume">On Hold</label>
                        </div>
                        
                    </v-card-title>
                    <v-card-text 
                        class="pipelineDetails"
                    >
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdFenceSendPOAndSchedule }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_FENCE_SENDPOANDSCHEDULE.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdFenceBarrierAndBondInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_FENCE_BARRIERANDBONDINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdFenceFinalInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_FENCE_FINALINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col 
                v-if="userLoggedGetters.profile.constructionFence != 0"
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <v-card @click="goToPipelineBBQ" class="bbq_alpha">
                    <v-card-title class="text-h6 bbq d-flex justify-space-between">

                        <div>
                            <v-icon left class="pipelineIconTitle">mdi mdi-grill-outline</v-icon>
                            <label class="pipelineLabelTitle">BBQ</label>
                        </div>

                        <div style="text-align: right;">
                            <label class="labelHoldResumeValue">{{ totalBBQ }}</label>
                            <label class="labelHoldResume">Open</label>
                            <label class="pipelineLabelTitle"> | </label>
                            <!-- <br /> -->
                            <label class="labelHoldResumeValue">0</label>
                            <label class="labelHoldResume">On Hold</label>
                        </div>

                    </v-card-title>
                    <v-card-text 
                        class="pipelineDetails"
                    >
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQUndergroundInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_UNDERGROUNDINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQFrameInstallation }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEINSTALLATION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQFrameAndTieDownInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEANDTIEDOWNINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQInstallRoughElectric }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_INSTALLROUGHELECTRIC.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQRoughElectricInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_ROUGHELECTRICINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQDryWallInstallation }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLINSTALLATION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQDryWallAndTieDownInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLANDTIEDOWNINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQCladdingAndCounterTopInstallation }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_CLADDINGANDCOUNTERTOPINSTALLATION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQInstallAppliances }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_INSTALLAPPLIANCES.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQFinalElectricInstallation }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICINSTALLATION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQFinalElectricFinalGasFinalPlumbingInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICFINALGASFINALPLUMBINGINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdBBQFinalInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_BBQ_FINALINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col 
                v-if="userLoggedGetters.profile.constructionPergola != 0"
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <v-card @click="goToPipelinePergola" class="pergola_alpha">
                    <v-card-title class="text-h6 pergola d-flex justify-space-between">

                        <div>
                            <v-icon left class="pipelineIconTitle">mdi mdi-home-roof</v-icon>
                            <label class="pipelineLabelTitle">Pergola</label>
                        </div>

                        <div style="text-align: right;">
                            <label class="labelHoldResumeValue">{{ totalPergola }}</label>
                            <label class="labelHoldResume">Open</label>
                            <label class="pipelineLabelTitle"> | </label>
                            <!-- <br /> -->
                            <label class="labelHoldResumeValue">0</label>
                            <label class="labelHoldResume">On Hold</label>
                        </div>

                    </v-card-title>
                    <v-card-text 
                        class="pipelineDetails"
                    >
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPergolaFooterFrame }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PERGOLA_FOOTERFRAME.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPergolaInspectionFooterBond }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PERGOLA_INSPECTIONSFOOTERBONDFOOTERFORMSFOOTERFOUNDATION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPergolaFooterPoured }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PERGOLA_FOOTERSPOURED.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPergolaFooterFinalInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PERGOLA_FOOTERFINALINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPergolaColumnInstallation }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PERGOLA_COLUMNINSTALLATION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPergolaElectricInstallation }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PERGOLA_ELECTRICINSTALLATION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPergolaFramingColumn }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PERGOLA_FRAMINGCOLUMNCOLUMNBONDFINALELECTRICINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPergolaFinishInstallation }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PERGOLA_FINISHINSTALLATION.description }}</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="1"
                                class="alignValue"
                            >
                                <label class="pipelineLabelValue">{{ qtdPergolaFinalInspection }}</label>
                            </v-col>
                            <v-col 
                                cols="8"
                                class="alignValueLabel"
                            >
                                <label class="pipelineLabel">{{ PROJECT_STATUS.CONSTRUCTION_PERGOLA_FINALINSPECTION.description }}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

		<v-dialog
			v-model="showMyTasks"
			transition="dialog-top-transition"
			persistent
			width="900"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
            <v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                    <v-col 
                        cols="6"
                    >
                        My Tasks
                    </v-col>
                </v-card-title>

                <v-card-text style="margin-top: 20px;">

                    <v-row>
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-data-table
                                style="margin-top: 10px;"
                                :headers="headersMyTasks"
                                :items="listMyTasks"
                                :options.sync="optionsMyTasks"
                                :loading="loading"
                                :items-per-page="10"
                                no-data-text="No Record Found"
                            >
                                <template v-slot:item.statusDescription="{ item }">
                                    <v-chip
                                        :color="getTasksStatus(item.status)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.statusDescription }}
                                    </v-chip>
                                </template>

                                <template v-slot:item.action="{ item }">
                                    <v-menu
                                        left
                                        bottom
                                    >

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="btnActions buttonMoreOptions"
                                                color=""
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item
                                                @click="showDialogActivities(
                                                    item.id,        //idServiceProject
                                                    1,              //idPanel
                                                    1,              //idStage
                                                    0,              //idAction
                                                    0,              //status
                                                    'Pre Site Verification',     //title
                                                    false,          //showScheduleDate
                                                    false,          //showPanelStages
                                                    false,          //showNotes
                                                    false,          //readOnlyNotes
                                                    false,          //showCheckList
                                                    false           //showSubContractor
                                                )"
                                            >
                                                <v-list-item-title><v-icon>mdi mdi-check</v-icon> Check </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions 
                    class="justify-center"
                >
                    <v-tooltip 
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2 dialogButtonDismiss"
                                v-on="on"
                                @click="showMyTasks = false"
                            >
                                Close
                            </v-btn>
                        </template>
                        <span>Close</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-speed-dial
            fixed
            v-model="openedFloatButton"
            :top="false"
            :bottom="true"
            :right="true"
            :left="false"
            :direction="'top'"
            :transition="'slide-y-reverse-transition'"
        >
            <template v-slot:activator>
                <v-btn
                    class="mx-2"
                    elevation="24"
                    outlined
                    x-large
                    fab
                    style="background-color: var(--color__red) !important; color: white !important;"
                >
                    <v-icon v-if="openedFloatButton">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-check-all
                    </v-icon>
                </v-btn>
            </template>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        small
                        v-on="on"
                        style="background-color: white !important;"
                        @click="showMyTasks = true" 
                    >
                        <v-icon>mdi-calendar-check-outline</v-icon>
                    </v-btn>
                </template>
                <span>My Tasks</span>
            </v-tooltip>
        </v-speed-dial>
        
        <DialogActivities
            :idServiceProject="dialogActivity.idServiceProject"
            :idPanel="dialogActivity.idPanel"
            :idStage="dialogActivity.idStage"
            :idAction="dialogActivity.idAction"
            :status="dialogActivity.status"
            :title="dialogActivity.title"
            :showScheduleDate="dialogActivity.showScheduleDate"
            :showPanelStages="dialogActivity.showPanelStages"
            :showNotes="dialogActivity.showNotes"
            :readOnlyNotes="dialogActivity.readOnlyNotes"
            :showCheckList="dialogActivity.showCheckList"
            :showSubContractor="dialogActivity.showSubContractor"
            v-on:methodConfirmToCall="dialogActivity.methodConfirm"
            :showDialogActivities.sync="dialogActivity.show"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import DialogActivities from "@/components/Services/DialogActivities";
    import { 
        PROJECT_STATUS
    } from "@/utilities/Enums";
    export default ({

        components: {
            DialogActivities
        },

        mixins: [Helpers],

        data: () => ({

            filter: {
                fastSearch: ''
            },

            //Pre-Dig
            qtdIncoming: 0,
            qtdPreSite: 0,
            qtdLocates: 0,
            qtdPreSiteResponsabilities: 0,
            qtdRebar: 0,

            //Front End
            qtdDigDemo: 0,
            qtdFormSteel: 0,
            qtdFrontEndInspections: 0,
            qtdPrePlumb: 0,
            qtdGunite: 0,
            qtdBackFill: 0,

            //Grading & Plumbing
            qtdPlumbing: 0,
            qtdPlumbingInspection: 0,
            qtdPatioGradeBondingGrid: 0,
            qtdBondingGridInspection: 0,

            //Brick & Tile / Deck
            qtdSendDelivery: 0,
            qtdDeckInstall: 0,
            qtdFinalGrade: 0,
            qtdCleanUp: 0,
            qtdDeckInspections: 0,

            //Equipment & Electric
            qtdEquipmentSet: 0,
            qtdElectricHookUp: 0,
            qtdPrePlasterResponsabilities: 0,
            qtdInspections: 0,

            //Plaster
            qtdFinalWalkthrough: 0,
            qtdVKBowlDeliveryAndInstall: 0,
            qtdPrep: 0,
            qtdPlaster: 0,
            qtdOpenItems: 0,

            //Fence
            qtdFenceSendPOAndSchedule: 0,
            qtdFenceBarrierAndBondInspection: 0,
            qtdFenceFinalInspection: 0,

            //BBQ
            qtdBBQUndergroundInspection: 0,
            qtdBBQFrameInstallation: 0,
            qtdBBQFrameAndTieDownInspection: 0,
            qtdBBQInstallRoughElectric: 0,
            qtdBBQRoughElectricInspection: 0,
            qtdBBQDryWallInstallation: 0,
            qtdBBQDryWallAndTieDownInspection: 0,
            qtdBBQCladdingAndCounterTopInstallation: 0,
            qtdBBQInstallAppliances: 0,
            qtdBBQFinalElectricInstallation: 0,
            qtdBBQFinalElectricFinalGasFinalPlumbingInspection: 0,
            qtdBBQFinalInspection: 0,

            //Pergola
            qtdPergolaFooterFrame: 0,
            qtdPergolaInspectionFooterBond: 0,
            qtdPergolaFooterPoured: 0,
            qtdPergolaFooterFinalInspection: 0,
            qtdPergolaColumnInstallation: 0,
            qtdPergolaElectricInstallation: 0,
            qtdPergolaFramingColumn: 0,
            qtdPergolaFinishInstallation: 0,
            qtdPergolaFinalInspection: 0,            

            openedFloatButton: false,
            showMyTasks: false,

            loading: false,
            
            listMyTasks: [ 
                { id: 1, userNameRegister: 'Rayane Tavares', dateRegisterFormatted: '04/27/2023 09:12', stageDescription: 'Pre-Dig -> Pre Site', actionDescription: 'Pre Site Verification', targetDateFormatted: '04/29/2023', status: 2, statusDescription: 'Scheduled'},
            ],

            headersMyTasks: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Phase", value: "stageDescription", sortable: true },
                { text: "Action", value: "actionDescription", sortable: true },
                { text: "Target", value: "targetDateFormatted", sortable: true },
                { text: "Created By", value: "userNameRegister", sortable: true },
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" },
            ],

            optionsMyTasks: { rowsPerPage: 10, page: 1 },

            dialogActivity: {
                idServiceProject: 0,
                idPanel: 0,
                idStage: 0,
                idAction: 0,
                status: 0,
                title: "",
                show: false,
                showScheduleDate: false,
                showPanelStages: false,
                showNotes: false,
                readOnlyNotes: false,
                showCheckList: false,
                showSubContractor: false,
                methodConfirm: ( () => {} ),
            },

            PROJECT_STATUS
            
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            totalPreDig() {
                return parseInt(this.qtdIncoming) + 
                       parseInt(this.qtdPreSite) +
                       parseInt(this.qtdLocates) +
                       parseInt(this.qtdPreSiteResponsabilities) +
                       parseInt(this.qtdRebar);
            },

            totalFrontEnd() {
                return parseInt(this.qtdDigDemo) + 
                       parseInt(this.qtdFormSteel) +
                       parseInt(this.qtdFrontEndInspections) +
                       parseInt(this.qtdPrePlumb) +
                       parseInt(this.qtdGunite) +
                       parseInt(this.qtdBackFill);
            },

            totalGradingPlumbing() {
                return parseInt(this.qtdPlumbing) + 
                       parseInt(this.qtdPlumbingInspection) +
                       parseInt(this.qtdPatioGradeBondingGrid) +
                       parseInt(this.qtdBondingGridInspection);
            },

            totalBrickTileDeck() {
                return parseInt(this.qtdSendDelivery) + 
                       parseInt(this.qtdDeckInstall) +
                       parseInt(this.qtdFinalGrade) +
                       parseInt(this.qtdCleanUp) +
                       parseInt(this.qtdDeckInspections);
            },

            totalEquipmentElectric() {
                return parseInt(this.qtdEquipmentSet) + 
                       parseInt(this.qtdElectricHookUp) +
                       parseInt(this.qtdPrePlasterResponsabilities) +
                       parseInt(this.qtdInspections);
            },

            totalPlaste() {
                return parseInt(this.qtdFinalWalkthrough) + 
                       parseInt(this.qtdVKBowlDeliveryAndInstall) +
                       parseInt(this.qtdPrep) +
                       parseInt(this.qtdPlaster) +
                       parseInt(this.qtdOpenItems);
            },

            totalFence() {
                return parseInt(this.qtdFenceSendPOAndSchedule) + 
                       parseInt(this.qtdFenceBarrierAndBondInspection) +
                       parseInt(this.qtdFenceFinalInspection);
            },

            totalBBQ() {
                return parseInt(this.qtdBBQUndergroundInspection) + 
                       parseInt(this.qtdBBQFrameInstallation) + 
                       parseInt(this.qtdBBQFrameAndTieDownInspection) + 
                       parseInt(this.qtdBBQInstallRoughElectric) + 
                       parseInt(this.qtdBBQRoughElectricInspection) + 
                       parseInt(this.qtdBBQDryWallInstallation) + 
                       parseInt(this.qtdBBQDryWallAndTieDownInspection) + 
                       parseInt(this.qtdBBQCladdingAndCounterTopInstallation) + 
                       parseInt(this.qtdBBQInstallAppliances) + 
                       parseInt(this.qtdBBQFinalElectricInstallation) + 
                       parseInt(this.qtdBBQFinalElectricFinalGasFinalPlumbingInspection) + 
                       parseInt(this.qtdBBQFinalInspection)
            },

            totalPergola() {
                return parseInt(this.qtdPergolaFooterFrame) + 
                       parseInt(this.qtdPergolaInspectionFooterBond) + 
                       parseInt(this.qtdPergolaFooterPoured) + 
                       parseInt(this.qtdPergolaFooterFinalInspection) + 
                       parseInt(this.qtdPergolaColumnInstallation) + 
                       parseInt(this.qtdPergolaElectricInstallation) + 
                       parseInt(this.qtdPergolaFramingColumn) + 
                       parseInt(this.qtdPergolaFinishInstallation) + 
                       parseInt(this.qtdPergolaFinalInspection)
            }
        },

        methods: {

            async getRegistersPreDig(listPipeline) {

                let listIncomingProjects = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS.id)];
                if (listIncomingProjects != null && listIncomingProjects != undefined) {
                    this.qtdIncoming = listIncomingProjects.length;
                }

                let listPreSite = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE.id)];
                if (listPreSite != null && listPreSite != undefined) {
                    this.qtdPreSite = listPreSite.length;
                }

                let listLocates = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_LOCALES.id)];
                if (listLocates != null && listLocates != undefined) {
                    this.qtdLocates = listLocates.length;
                }

                let listPreSiteResponsabilities = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE_RESP.id)];
                if (listPreSiteResponsabilities != null && listPreSiteResponsabilities != undefined) {
                    this.qtdPreSiteResponsabilities = listPreSiteResponsabilities.length;
                }

                let listRebar = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_REBAR.id)];
                if (listRebar != null && listRebar != undefined) {
                    this.qtdRebar = listRebar.length;
                }
            },

            async getRegistersFrontEnd(listPipeline) {

                let listDigDemo = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_DIGDEMO.id)];
                if (listDigDemo != null && listDigDemo != undefined) {
                    this.qtdDigDemo = listDigDemo.length;
                }

                let listFormSteel = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_FORMSTEEL.id)];
                if (listFormSteel != null && listFormSteel != undefined) {
                    this.qtdFormSteel = listFormSteel.length;
                }

                let listFrontEndInspections = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_FRONTENDINSPECTION.id)];
                if (listFrontEndInspections != null && listFrontEndInspections != undefined) {
                    this.qtdFrontEndInspections = listFrontEndInspections.length;
                }

                let listPrePlumb = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_PREPLUMB.id)];
                if (listPrePlumb != null && listPrePlumb != undefined) {
                    this.qtdPrePlumb = listPrePlumb.length;
                }

                let listGunite = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_GUNITE.id)];
                if (listGunite != null && listGunite != undefined) {
                    this.qtdGunite = listGunite.length;
                }

                let listBackFill = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_BACKFILL.id)];
                if (listBackFill != null && listBackFill != undefined) {
                    this.qtdBackFill = listBackFill.length;
                }
            },

            async getRegistersGradingPlumbing(listPipeline) {

                let listPlumbing = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.id)];
                if (listPlumbing != null && listPlumbing != undefined) {
                    this.qtdPlumbing = listPlumbing.length;
                }

                let listPlumbingInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBINGINSPECTION.id)];
                if (listPlumbingInspection != null && listPlumbingInspection != undefined) {
                    this.qtdPlumbingInspection = listPlumbingInspection.length;
                }

                let listPatioGradeBondingGrid = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PATIOGRADE.id)];
                if (listPatioGradeBondingGrid != null && listPatioGradeBondingGrid != undefined) {
                    this.qtdPatioGradeBondingGrid = listPatioGradeBondingGrid.length;
                }

                let listBondingGridInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_DECKBONDINSPECTION.id)];
                if (listBondingGridInspection != null && listBondingGridInspection != undefined) {
                    this.qtdBondingGridInspection = listBondingGridInspection.length;
                }
            },

            async getRegistersBrickTileDeck(listPipeline) {
                
                let listSendDelivery = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL.id)];
                if (listSendDelivery != null && listSendDelivery != undefined) {
                    this.qtdSendDelivery = listSendDelivery.length;
                }

                let listDeckInstall = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSTALL.id)];
                if (listDeckInstall != null && listDeckInstall != undefined) {
                    this.qtdDeckInstall = listDeckInstall.length;
                }

                let listFinalGrade = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_FINALGRADE.id)];
                if (listFinalGrade != null && listFinalGrade != undefined) {
                    this.qtdFinalGrade = listFinalGrade.length;
                }

                let listCleanUp = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_CLEANUP.id)];
                if (listCleanUp != null && listCleanUp != undefined) {
                    this.qtdCleanUp = listCleanUp.length;
                }

                let listDeckInspections = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSPECTION.id)];
                if (listDeckInspections != null && listDeckInspections != undefined) {
                    this.qtdDeckInspections = listDeckInspections.length;
                }
            },

            async getRegistersEquipmentElectric(listPipeline) {
                
                let listEquipmentSet = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_EQUIPMENTSET.id)];
                if (listEquipmentSet != null && listEquipmentSet != undefined) {
                    this.qtdEquipmentSet = listEquipmentSet.length;
                }

                let listElectricHookUp = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_ELECTRICHOOKUP.id)];
                if (listElectricHookUp != null && listElectricHookUp != undefined) {
                    this.qtdElectricHookUp = listElectricHookUp.length;
                }

                let listPrePlasterResponsabilities = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_PREPLASTER.id)];
                if (listPrePlasterResponsabilities != null && listPrePlasterResponsabilities != undefined) {
                    this.qtdPrePlasterResponsabilities = listPrePlasterResponsabilities.length;
                }

                let listInspections = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_INSPECTION.id)];
                if (listInspections != null && listInspections != undefined) {
                    this.qtdInspections = listInspections.length;
                }
            },

            async getRegistersPlaste(listPipeline) {

                let listFinalWalkthrough = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_FINALWALKTHROUGHT.id)];
                if (listFinalWalkthrough != null && listFinalWalkthrough != undefined) {
                    this.qtdFinalWalkthrough = listFinalWalkthrough.length;
                }

                let listVKBowlDeliveryAndInstall = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_VKBOWLDELIVERY.id)];
                if (listVKBowlDeliveryAndInstall != null && listVKBowlDeliveryAndInstall != undefined) {
                    this.qtdVKBowlDeliveryAndInstall = listVKBowlDeliveryAndInstall.length;
                }

                let listPrep = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_PREP.id)];
                if (listPrep != null && listPrep != undefined) {
                    this.qtdPrep = listPrep.length;
                }

                let listPlaster = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_PLASTER.id)];
                if (listPlaster != null && listPlaster != undefined) {
                    this.qtdPlaster = listPlaster.length;
                }

                let listOpenItems = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id)];
                if (listOpenItems != null && listOpenItems != undefined) {
                    this.qtdOpenItems = listOpenItems.length;
                }
            },

            async getRegistersFence(listPipeline) {

                let listSendPOAndSchedule = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_FENCE_SENDPOANDSCHEDULE.id)];
                if (listSendPOAndSchedule != null && listSendPOAndSchedule != undefined) {
                    this.qtdSendPOAndSchedule = listSendPOAndSchedule.length;
                }

                let listBarrierAndBondInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_FENCE_BARRIERANDBONDINSPECTION.id)];
                if (listBarrierAndBondInspection != null && listBarrierAndBondInspection != undefined) {
                    this.qtdBarrierAndBondInspection = listBarrierAndBondInspection.length;
                }

                let listFinalInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_FENCE_FINALINSPECTION.id)];
                if (listFinalInspection != null && listFinalInspection != undefined) {
                    this.qtdFinalInspection = listFinalInspection.length;
                }
            },

            async getRegistersBBQ(listPipeline) {

                let listUndergroundInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_UNDERGROUNDINSPECTION.id)];
                if (listUndergroundInspection != null && listUndergroundInspection != undefined) {
                    this.qtdBBQUndergroundInspection = listUndergroundInspection.length;
                }

                let listFrameInstallation = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEINSTALLATION.id)];
                if (listFrameInstallation != null && listFrameInstallation != undefined) {
                    this.qtdBBQFrameInstallation = listFrameInstallation.length;
                }

                let listFrameAndTieDownInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEANDTIEDOWNINSPECTION.id)];
                if (listFrameAndTieDownInspection != null && listFrameAndTieDownInspection != undefined) {
                    this.qtdBBQFrameAndTieDownInspection = listFrameAndTieDownInspection.length;
                }

                let listInstallRoughElectric = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_INSTALLROUGHELECTRIC.id)];
                if (listInstallRoughElectric != null && listInstallRoughElectric != undefined) {
                    this.qtdBBQInstallRoughElectric = listInstallRoughElectric.length;
                }

                let listRoughElectricInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_ROUGHELECTRICINSPECTION.id)];
                if (listRoughElectricInspection != null && listRoughElectricInspection != undefined) {
                    this.qtdBBQRoughElectricInspection = listRoughElectricInspection.length;
                }

                let listDryWallInstallation = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLINSTALLATION.id)];
                if (listDryWallInstallation != null && listDryWallInstallation != undefined) {
                    this.qtdBBQDryWallInstallation = listDryWallInstallation.length;
                }

                let listDryWallAndTieDownInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLANDTIEDOWNINSPECTION.id)];
                if (listDryWallAndTieDownInspection != null && listDryWallAndTieDownInspection != undefined) {
                    this.qtdBBQDryWallAndTieDownInspection = listDryWallAndTieDownInspection.length;
                }

                let listCladdingAndCounterTopInstallation = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_CLADDINGANDCOUNTERTOPINSTALLATION.id)];
                if (listCladdingAndCounterTopInstallation != null && listCladdingAndCounterTopInstallation != undefined) {
                    this.qtdBBQCladdingAndCounterTopInstallation = listCladdingAndCounterTopInstallation.length;
                }

                let listInstallAppliances = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_INSTALLAPPLIANCES.id)];
                if (listInstallAppliances != null && listInstallAppliances != undefined) {
                    this.qtdBBQInstallAppliances = listInstallAppliances.length;
                }

                let listFinalElectricInstallation = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICINSTALLATION.id)];
                if (listFinalElectricInstallation != null && listFinalElectricInstallation != undefined) {
                    this.qtdBBQFinalElectricInstallation = listFinalElectricInstallation.length;
                }

                let listFinalElectricFinalGasFinalPlumbingInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICFINALGASFINALPLUMBINGINSPECTION.id)];
                if (listFinalElectricFinalGasFinalPlumbingInspection != null && listFinalElectricFinalGasFinalPlumbingInspection != undefined) {
                    this.qtdBBQFinalElectricFinalGasFinalPlumbingInspection = listFinalElectricFinalGasFinalPlumbingInspection.length;
                }

                let listFinalInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALINSPECTION.id)];
                if (listFinalInspection != null && listFinalInspection != undefined) {
                    this.qtdBBQFinalInspection = listFinalInspection.length;
                }
            },

            async getRegistersPergola(listPipeline) {

                let listFooterFrame = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_FOOTERFRAME.id)];
                if (listFooterFrame != null && listFooterFrame != undefined) {
                    this.qtdPergolaFooterFrame = listFooterFrame.length;
                }

                let listInspectionFooterBond = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_INSPECTIONSFOOTERBONDFOOTERFORMSFOOTERFOUNDATION.id)];
                if (listInspectionFooterBond != null && listInspectionFooterBond != undefined) {
                    this.qtdPergolaPergolaInspectionFooterBond = listInspectionFooterBond.length;
                }

                let listFooterPoured = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_FOOTERSPOURED.id)];
                if (listFooterPoured != null && listFooterPoured != undefined) {
                    this.qtdPergolaFooterPoured = listFooterPoured.length;
                }

                let listFooterFinalInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_FOOTERFINALINSPECTION.id)];
                if (listFooterFinalInspection != null && listFooterFinalInspection != undefined) {
                    this.qtdPergolaFooterFinalInspection = listFooterFinalInspection.length;
                }

                let listColumnInstallation = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_COLUMNINSTALLATION.id)];
                if (listColumnInstallation != null && listColumnInstallation != undefined) {
                    this.qtdPergolaColumnInstallation = listColumnInstallation.length;
                }

                let listElectricInstallation = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_ELECTRICINSTALLATION.id)];
                if (listElectricInstallation != null && listElectricInstallation != undefined) {
                    this.qtdPergolaElectricInstallation = listElectricInstallation.length;
                }

                let listFramingColumn = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_FRAMINGCOLUMNCOLUMNBONDFINALELECTRICINSPECTION.id)];
                if (listFramingColumn != null && listFramingColumn != undefined) {
                    this.qtdPergolaFramingColumn = listFramingColumn.length;
                }

                let listFinishInstallation = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_FINISHINSTALLATION.id)];
                if (listFinishInstallation != null && listFinishInstallation != undefined) {
                    this.qtdPergolaFinishInstallation = listFinishInstallation.length;
                }

                let listFinalInspection = [...listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_FINALINSPECTION.id)];
                if (listFinalInspection != null && listFinalInspection != undefined) {
                    this.qtdPergolaFinalInspection = listFinalInspection.length;
                }
            },

            async getRegisters() {

                let response = await this.$store.dispatch("serviceModule/ListPipelineConstruction");

                if (response != null && response != undefined) {

                    if (response.listPipeline && response.listPipeline != null && response.listPipeline != undefined) {
                        this.getRegistersPreDig(response.listPipeline);
                        this.getRegistersFrontEnd(response.listPipeline);
                        this.getRegistersGradingPlumbing(response.listPipeline);
                        this.getRegistersBrickTileDeck(response.listPipeline);
                        this.getRegistersEquipmentElectric(response.listPipeline);
                        this.getRegistersPlaste(response.listPipeline);
                        this.getRegistersFence(response.listPipeline);
                        this.getRegistersBBQ(response.listPipeline);
                        this.getRegistersPergola(response.listPipeline);
                    }
                }
            },

            async goToPipelinePreDig() {
                this.$router.push({ path: "/construction/pipelinePreDig" });
            },

            async goToPipelineFrontEnd() {
                this.$router.push({ path: "/construction/pipelineFrontEnd" });
            },

            async goToPipelineGradingPlumbing() {
                this.$router.push({ path: "/construction/pipelineGradingPlumbing" });
            },

            async goToPipelineTileDeck() {
                this.$router.push({ path: "/construction/pipelineTileDeck" });
            },

            async goToPipelineEquipmentElectric() {
                this.$router.push({ path: "/construction/pipelineEquipmentElectric" });
            },

            async goToPipelinePrepPlaster() {
                this.$router.push({ path: "/construction/pipelinePrepPlaster" });
            },

            async goToPipelineFence() {
                this.$router.push({ path: "/construction/pipelineFence" });
            },

            async goToPipelineBBQ() {
                this.$router.push({ path: "/construction/pipelineBBQ" });
            },

            async goToPipelinePergola() {
                this.$router.push({ path: "/construction/pipelinePergola" });
            },
			
            getTasksStatus (status) {

                let color = "";

                switch (status) {

                    case 1:
                        color = 'var(--color__status_pending)';
                        break;

                    case 2:
                        color = 'var(--color__main)';
                        break;

                    case 3:
                        color = 'var(--color__completed)';
                        break;

                    case 4:
                        color = 'var(--color__red)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },

            async showDialogActivities(
                idServiceProject, 
                idPanel, 
                idStage, 
                idAction, 
                status, 
                title, 
                showScheduleDate, 
                showPanelStages, 
                showNotes, 
                readOnlyNotes, 
                showCheckList, 
                showSubContractor
            ) {
                
                this.dialogActivity = {
                    idServiceProject,
                    idPanel: idPanel,
                    idStage: idStage,
                    idAction: idAction,
                    status: status,
                    title: title,
                    show: true,
                    showScheduleDate,
                    showPanelStages,
                    showNotes,
                    readOnlyNotes,
                    showCheckList,
                    showSubContractor,
                    methodConfirm: () => {}
                }
            },
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        },
    })
</script>

<style scoped>

    label {
        font-size: 14px !important;
        color: #5e5e5e;
    }

    .pipelineDetails {
        margin-top: 15px;
    }

    .pipelineIconTitle {
        color: white !important;
    }

    .pipelineLabelTitle {
        color: white !important;
        font-size: 17px;
    }

    .labelHoldResume {
        color: rgb(255, 255, 255, 0.8) !important;
        font-size: 17px;
    }

    .labelHoldResumeValue {
        color: white !important;
        font-size: 17px !important;
        margin-right: 5px;
    }

    .pipelineLabel {
        font-size: 17px;
    }

    .pipelineLabelValue {
        color: var(--color__main) !important;
        font-weight: bold !important; 
        font-size: 20px !important;
    }

    .pipelineLabelRedValue {
        color: var(--color__preDig_alert) !important;
        font-weight: bold !important; 
        font-size: 20px !important;
    }

    .pipelineLabelValueBlank {
        color: transparent !important;
        font-weight: bold !important; 
        font-size: 20px !important;
    }

    .alignValue {
        margin-left: 15px;
    }

    .alignValueLabel {
        margin-left: 35px;
    }

    .preDig {
        background-color: var(--color__construction_dashboard_predig);
    }

    .preDig_alpha {
        background-color: var(--color__construction_dashboard_predig_alpha);
    }

    .frontend {
        background-color: var(--color__construction_dashboard_frontend);
    }

    .frontend_alpha {
        background-color: var(--color__construction_dashboard_frontend_alpha);
    }

    .grading_plumbing {
        background-color: var(--color__construction_dashboard_grading_plumbing);
    }

    .grading_plumbing_alpha {
        background-color: var(--color__construction_dashboard_grading_plumbing_alpha);
    }

    .tiledeck {
        background-color: var(--color__construction_dashboard_tiledeck);
    }

    .tiledeck_alpha {
        background-color: var(--color__construction_dashboard_tiledeck_alpha);
    }

    .equipment_electric {
        background-color: var(--color__construction_dashboard_equipment_electric);
    }

    .equipment_electric_alpha {
        background-color: var(--color__construction_dashboard_equipment_electric_alpha);
    }

    .prep_plaster {
        background-color: var(--color__construction_dashboard_prep_plaster);
    }

    .prep_plaster_alpha {
        background-color: var(--color__construction_dashboard_prep_plaster_alpha);
    }

    .fence {
        background-color: var(--color__construction_dashboard_fence);
    }

    .fence_alpha {
        background-color: var(--color__construction_dashboard_fence_alpha);
    }

    .bbq {
        background-color: var(--color__construction_dashboard_bbq);
    }

    .bbq_alpha {
        background-color: var(--color__construction_dashboard_bbq_alpha);
    }

    .pergola {
        background-color: var(--color__construction_dashboard_pergola);
    }

    .pergola_alpha {
        background-color: var(--color__construction_dashboard_pergola_alpha);
    }

    div[class^='col-'] {
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>